.home {
  --title-height: 100px;
  height: 100vh;
  display: grid;
  grid-template-rows: var(--title-height) 1fr;
  background-color: #EFE9D3;
}

.home__title {
  padding: 12px;
  display: flex;
  justify-content: center;
}

.home__main {
  height: calc(100vh - var(--title-height));
  overflow: auto;
}

.card-game {
  display: flex;
  grid-gap: 32px;
  justify-content: center;
}

.card-grid {
  display: grid;
  grid-gap: 12px;
}
