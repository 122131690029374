.card {
  --card-radius: 10px;
  height: 125px;
  width: 125px;
  border: 1px solid #e9c46a;
  border-radius: var(--card-radius);
}

.card.--facedown {
  background-color: #e9c46a;
}

.card.--faceup {
  border: unset;
}

.card.--matched {
  border: unset;
  opacity: 0.6; 
}

.card__icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: var(--card-radius);
}
