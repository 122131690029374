.actionbar {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.actionbar > * {
    margin-bottom: 16px;

}

.actionbar > *:last-child {
    margin-bottom: 0;
}